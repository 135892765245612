import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as RankPointAPI from "@/api/analysis/rank-point";
import { isSuccess } from "@/api/response";
import { RankPointGetRequest } from "@/api/analysis/rank-point/request";
import { RankPointGetResponse } from "@/api/analysis/rank-point/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/rank-point/get";

/**
 *  ランク(ポイント)API（/analysis-rank-point）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: RankPointGetRequest = {} as RankPointGetRequest;
  getResponse: RankPointGetResponse = {} as RankPointGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getData() {
    if (this.getResponse.results) {
      return this.getResponse.results.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: RankPointGetRequest) {
    const getResponse = await RankPointAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as RankPointGetResponse
    };
  }
}

export default getModule(Get);
