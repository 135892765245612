import service from "@/api/service";
import { RankPointGetRequest } from "@/api/analysis/rank-point/request";
import { RankPointGetResponse } from "@/api/analysis/rank-point/response";

/**
 * ランク(ポイント)APIをコールします。
 *
 * @param getRequest ランク(ポイント)APIのリクエストボディ
 * @return RankPointGetResponse
 */
export async function get(getRequest: RankPointGetRequest) {
  const response = await service.post("/analysis-rank-point", getRequest);
  return response.data as RankPointGetResponse;
}
