import { Component, Mixins } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import RankPointChart from "@/views/analysis/RankPointChart.vue";
import RankPointGet from "@/store/analysis/rank-point/get";
import { RankPointGetRequest } from "@/api/analysis/rank-point/request";
import Admin from "@/store/admin/admin";

export interface RankPointInputOptions {
  date: string;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    RankPointChart
  }
})
export default class AnalysisRankPoint extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ランク (ポイント)";
  headingSub = "Rank (Point)";
  breadCrumbs = [{ text: "ランク (ポイント)", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedRankPointData = false;

  // DatePickerの表示フラグ
  dateModal = null;

  // 検索入力オプション
  inputOptions: RankPointInputOptions = {
    date: this.initDate,
    selectShop: null
  };

  /**
   * 総件数
   */
  get totalCount() {
    return RankPointGet.getTotalCount;
  }

  get rankPointData() {
    return RankPointGet.getData;
  }

  get dateForView() {
    return this.replaceHyphenToSlash(this.inputOptions.date);
  }
  set dateForView(date) {
    this.inputOptions.date = this.replaceSlashToHyphen(date);
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDate() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      d = date.getDate();
    const firstDate = new Date(y, m, d);
    return this.stringFromDate(firstDate);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.inputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchRootChartData();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await RankPointGet.clearResponse();
  }

  async fetchRootChartData() {
    this.isLoadedRankPointData = false;
    await this.fetchRankPoint();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchRankPoint(): Promise<boolean> {
    this.isLoadedRankPointData = false;
    await Flash.clear();
    const rankPointGetRequest: RankPointGetRequest = {
      date: this.dateForView,
      searchShopId: this.inputOptions.selectShop
        ? Number(this.inputOptions.selectShop.id)
        : null
    };
    await RankPointGet.get(rankPointGetRequest);
    if (!RankPointGet.isSuccess) {
      await Flash.setErrorNow({
        message: RankPointGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedRankPointData = true;
    return RankPointGet.isSuccess;
  }
}
